@charset "UTF-8";

@import "settings";
@import "mixins";

/*****************************************************************************
Responsive Block
*****************************************************************************/
#Wrapper {
  position: relative;
  left: 0;
}

#additional-menu {
  overflow-y: auto;
  max-height: 100%;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 185px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999;
  width: 1400px;
  padding: 35px 60px;
  box-shadow: 0 8px 10px rgba(14, 12, 16, 0.2);
  background: #fff url(../img/shadow.png) repeat-x 0 0;
  @include transition(all 0.3s ease);
  .submenu-links2 {
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 20%;
          font-size: 14px;
          line-height: 18px;
          font-weight: 300;
          text-transform: capitalize;
          a {
            display: block;
            padding: 8px 15px 8px 0;
            color: #333;
            &:hover {
              color: $color-links;
            }
          }
        }
      }
    }
  &.active {
    visibility: visible;
    opacity: 1;
  }
  .additional-menu__responsive {
    display: none;
    position: relative;
    .close-side-panel {
      position: absolute;
      top: 25px;
      right: 20px;
      display: block;
      width: 18px;
      height: 18px;
      span {
        display: block;
        margin-left: 9px;
        width: 1px;
        height: 21px;
        background: #262625;
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: -10px;
          top: 10px;
          width: 21px;
          height: 1px;
          background: #262625;
        }
      }
    }
    .additional-menu__top {
      display: flex;
      background: #fff;
      a.logo {
        display: block;
        width: 176px;
        height: 73px;
        background: url(../img/logo.png) no-repeat 0 0;
        background-size: 100% auto;
        text-indent: -9999px;
        @include transition(all 0.7s ease);
      }
      .social {
        display: flex;
        align-items: center;
        margin-left: 50px;
        a {
          display: block;
          margin-left: 21px;
          width: 24px;
          height: 24px;
          text-indent: -9999px;
          &:first-child {
            margin-left: 0;
          }
          &.fb {
            background: url(../img/icons/fb.png) no-repeat 0 0;
          }
          &.insta {
            background: url(../img/icons/insta.png) no-repeat 0 0;
          }
        }
      }
    }
    .additional-menu__bottom {
      display: none;
      flex-wrap: wrap;
      border-bottom: 1px solid #e7e7e7;
      a {
        display: block;
        width: 50%;
        padding: 30px 0 5px;
        color: #999;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        @include transition(all 0.4s ease);
        &:hover {
          color: $color-links;
        }
        &.wishlist-link {
          background: url(../img/icons/favorite.png) no-repeat center 7px;
          border-right: 1px solid #e7e7e7;
        }
        &.compare-link {
          background: url(../img/icons/compare.png) no-repeat center 7px;
        }
      }
    }
  }
}

body.sticky #additional-menu {
  top: 88px;
}

#menu-responsive {
  padding: 0 20px;
  ul {
    li {
      border-bottom: 1px solid #e7e7e7;
      &.submenu {
        background: url(../img/icons/arrow-bottom.png) no-repeat right 20px;
        &.active {
          background: url(../img/icons/arrow-top.png) no-repeat right 20px;
          .level-links {
            display: block;
          }
        }
      }
      a {
        display: block;
        padding: 10px 0;
        color: #999;
        font-size: 16px;
        font-weight: 300;
        @include transition(all 0.3s ease);
        &:hover {
          color: $color-turquoise;
        }
      }
      .level-links {
        display: none;
        ul {
          border-top: 1px solid #e7e7e7;
          li {
            &:last-child {
              border-bottom: 0;
            }
            a {
              padding-left: 30px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

/*****************************************************************************
Side panel
*****************************************************************************/
.page-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: rgba(0,0,0,0.6);
  cursor: pointer;
  @include transition(all 0.4s ease);
  &.active {
    display: block;
  }
}

.side-panel {
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -400px;
  width: 400px;
  padding: 40px 32px 44px;
  background: #fff;
  z-index: 9999;
  overflow-y: auto;
  max-height: 100%;
  @include transition(all 0.6s ease);
  &.active {
    visibility: visible;
    right: 0;
  }
  .close-side-panel {
    position: absolute;
    top: 15px;
    right: 20px;
    display: block;
    width: 18px;
    height: 18px;
    span {
      display: block;
      margin-left: 9px;
      width: 1px;
      height: 21px;
      background: #262625;
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -10px;
        top: 10px;
        width: 21px;
        height: 1px;
        background: #262625;
      }
    }
  }
}

.user-panel {
  .top {
    margin-bottom: 20px;
    padding: 9px 0 9px 72px;
    background: url(../img/icons/user-panel.png) no-repeat 0 0;
    h5 {
      color: #222;
      font-size: 17px;
      line-height: 19px;
      font-weight: 400;
    }
    p {
      margin: 0;
      color: #94999c;
      font-size: 13px;
      font-weight: 300;
      word-wrap: break-word;
    }
  }
  .options {
    .item {
      position: relative;
      border-bottom: 1px solid #e7e7e7;
      &:last-child {
        border: 0;
      }
      a {
        display: block;
        padding: 24px 14px 25px;
        color: #222;
        font-size: 15px;
        line-height: 19px;
        font-weight: 400;
        @include transition(all 0.3s ease);
        &:hover {
          color: $color-links;
        }
        &.red {
          color: #e50303;
          &:hover {
            color: #e50303;
            text-decoration: underline;
          }
        }
      }
      .counter-number {
        position: absolute;
        top: 24px;
        right: 14px;
        padding: 4px 7px;
        line-height: 13px;
        color: #fff;
        font-weight: 500;
        font-size: 13px;
        background: #1cc0a0;
        @include border-radius(50%);
      }
    }
  }
}

.user-login {
  .inside {
    .items {
      .item {
        margin-bottom: 10px;
        input[type="email"] {
          padding-left: 35px;
          background: url(../img/icons/email.jpg) 10px 15px no-repeat;
        }
        input[type="password"] {
          padding-left: 35px;
          background: url(../img/icons/lock_grey.svg) 10px 15px no-repeat;
        }
      }
    }
    a.link-right {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0;
      color: #666;
      font-size: 13px;
      font-weight: 300;
      cursor: pointer;
      &:hover {
        color: $color-links;
        text-decoration: underline;
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;
      .btn {
        width: 48.5%;
      }
    }
    .btn-transparent-black {
      width: 100%;
    }
  }
}

.user-registration {
  .inside {
    .btn-transparent-black {
      width: 100%;
      margin-bottom: 40px;
    }
    .items {
      .item {
        margin-bottom: 10px;
        &.item-group {
          display: flex;
          justify-content: space-between;
          .form-control {
            width: 49%;
          }
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      margin: 27px 0 60px;
      .btn {
        width: 48.5%;
      }
    }
  }
}

.minicart {
  padding-bottom: 14px;
  .inside {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-content: space-between;
    .top {
      width: 100%;
    }
    p.number {
      margin-bottom: 13px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e7e7e7;
      color: #222;
      font-size: 14px;
      line-height: 18px;
      font-weight: 300;
      text-align: center;
      strong {
        font-weight: 600;
      }
    }
    .free-delivery {
      margin-bottom: 26px;
      padding: 0 10px 15px;
      border-bottom: 1px solid #e7e7e7;
      .progress {
        margin-bottom: 13px;
      }
      p {
        margin: 0;
        color: #666;
        font-size: 13px;
        line-height: 15px;
        font-weight: 300;
        text-align: center;
        span {
          color: #e53939;
        }
      }
    }
    .product-list {
      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e7e7e7;
        .photo {
          width: 56px;
          height: 79px;
          margin-right: 19px;
          a {
            display: block;
            img {
              display: block;
              max-width: 100%;
            }
          }
        }
        .desc {
          flex: 1;
          padding-top: 10px;
          p.name {
            margin: 0;
            color: #7f7f7f;
            font-size: 13px;
            line-height: 13px;
            font-weight: 400;
            a {
              color: #7f7f7f;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            .left-col {
              p {
                margin: 0;
                color: #a8a8a8;
                font-size: 13px;
                line-height: 15px;
                font-weight: 300;
              }
            }
            .right-col {
              p.price {
                margin-bottom: 3px;
                color: #000;
                font-size: 15px;
                line-height: 17px;
                font-weight: 500;
                text-align: right;
              }
              a.remove {
                display: inline-block;
                padding: 3px 0;
                color: #a8a8a8;
                font-size: 11px;
                line-height: 13px;
                font-weight: 300;
                cursor: pointer;
                text-align: right;
                &:hover {
                  color: #767575;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
    .minicart-bottom {
      align-items: flex-end;
      width: 100%;
    }
    .total-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      p {
        margin: 0;
        &.text {
          margin-right: 10px;
          color: #000;
          font-size: 15px;
          font-weight: 500;
          span {
            color: #c4c4c4;
            font-size: 10px;
            font-weight: 600;
          }
        }
        &.price {
          color: #000;
          font-size: 19px;
          font-weight: 600;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      .btn {
        width: 48.5%;
      }
    }
  }
}

.wishlist-panel {
  .inside {
    .product-list {
      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e7e7e7;
        .photo {
          width: 56px;
          height: 79px;
          margin-right: 19px;
          a {
            display: block;
            img {
              display: block;
              max-width: 100%;
            }
          }
        }
        .desc {
          flex: 1;
          padding-top: 10px;
          p.name {
            margin: 0;
            color: #7f7f7f;
            font-size: 13px;
            line-height: 13px;
            font-weight: 400;
            a {
              color: #7f7f7f;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            .left-col {
              p {
                margin: 0;
                color: #a8a8a8;
                font-size: 13px;
                line-height: 15px;
                font-weight: 300;
              }
            }
            .right-col {
              p.price {
                margin-bottom: 3px;
                color: #000;
                font-size: 15px;
                line-height: 17px;
                font-weight: 500;
                text-align: right;
              }
              a.remove {
                display: inline-block;
                padding: 3px 0;
                color: #a8a8a8;
                font-size: 11px;
                line-height: 13px;
                font-weight: 300;
                cursor: pointer;
                text-align: right;
                &:hover {
                  color: #767575;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}

h3.h3-panel {
  margin-bottom: 18px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e7e7e7;
  color: #222;
  font-size: 17px;
  line-height: 19px;
  font-weight: 300;
}

.regulations {
  p.text-small {
    margin: 0;
    font-size: 11px;
    line-height: 11px;
    a {
      color: $color-links;
    }
  }
}

.lang-box {
  display: none;
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 1400px;
  padding: 54px 74px;
  background: #fff;
  z-index: 999;
  overflow-y: auto;
  max-height: 80%;
  @include transition(all 0.6s ease);
  &.active {
    display: block;
  }
  .inside {
    display: flex;
    .left-col {
      flex: 1;
      border-right: 1px solid #e7e7e7;
    }
    .right-col {
      width: 450px;
      padding-left: 82px;
      .item-select {
        position: relative;
        margin: 40px 0;
        .name {
          position: absolute;
          top: -7px;
          left: 7px;
          z-index: 9;
          padding: 0 3px;
          background-color: #fff;
          color: #767676;
          font-family: 'Roboto', sans-serif;
          font-size: 13px;
          line-height: 15px;
        }
        .select2-container {
          width: 100% !important;
        }
      }
      .btn-turquoise {
        min-width: 163px;
        float: right;
      }
    }
    h5 {
      margin-bottom: 35px;
      color: #222;
      font-size: 15px;
      line-height: 19px;
      font-weight: 500;
    }
    p {
      color: #222;
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
    }
  }
  .language-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      margin-bottom: 18px;
      width: 33%;
      a {
        display: block;
        padding-left: 38px;
        color: #333;
        font-size: 14px;
        line-height: 18px;
        font-weight: 300;
        @include transition(all 0.4s ease);
        &:hover {
          color: $color-links;
        }
      }
      &.pl {
        background: url(../img/icons/pl.png) no-repeat 0 0;
      }
      &.en {
        background: url(../img/icons/en.png) no-repeat 0 0;
      }
      &.de {
        background: url(../img/icons/de.png) no-repeat 0 0;
      }
      &.cz {
        background: url(../img/icons/cz.png) no-repeat 0 0;
      }
      &.sk {
        background: url(../img/icons/sk.png) no-repeat 0 0;
      }
      &.ua {
        background: url(../img/icons/ua.png) no-repeat 0 0;
      }
      &.fr {
        background: url(../img/icons/fr.png) no-repeat 0 0;
      }
      &.hu {
        background: url(../img/icons/hu.png) no-repeat 0 0;
      }
      &.it {
        background: url(../img/icons/it.png) no-repeat 0 0;
      }
      &.ro {
        background: url(../img/icons/ro.png) no-repeat 0 0;
      }
      &.se {
        background: url(../img/icons/se.png) no-repeat 0 0;
      }
      &.no {
        background: url(../img/icons/no.png) no-repeat 0 0;
      }
    }
  }
  .close-side-panel {
    position: absolute;
    top: 15px;
    right: 20px;
    display: block;
    width: 18px;
    height: 18px;
    span {
      display: block;
      margin-left: 9px;
      width: 1px;
      height: 21px;
      background: #262625;
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -10px;
        top: 10px;
        width: 21px;
        height: 1px;
        background: #262625;
      }
    }
  }
}

.popup {
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 640px;
  height: 510px;
  padding: 40px 30px 44px;
  background: #fff;
  z-index: 9999;
  overflow-y: auto;
  max-height: 100%;
  h3 {
    margin-bottom: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e7e7e7;
    color: #222;
    font-size: 20px;
    line-height: 23px;
    font-weight: 300;
  }
  p {
    margin-bottom: 10px;
    color: #222;
    font-size: 14px;
    font-weight: 300;
  }
  &.active {
    visibility: visible;
  }
  .close-side-panel {
    position: absolute;
    top: 15px;
    right: 20px;
    display: block;
    width: 18px;
    height: 18px;
    span {
      display: block;
      margin-left: 9px;
      width: 1px;
      height: 21px;
      background: #262625;
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -10px;
        top: 10px;
        width: 21px;
        height: 1px;
        background: #262625;
      }
    }
  }
}

/*****************************************************************************
Header
*****************************************************************************/
#Header {
  position: fixed;
  z-index: 997;
  width: 100%;
  height: 185px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
  .header-top {
    background-color: #f8f8f8;
    @include transition(all 0.7s ease);
    .inside {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      .left-col {
        ul {
          display: flex;
          li {
            margin-right: 29px;
            &:last-child {
              margin-right: 0;
            }
            a {
              color: #999;
              font-size: 13px;
              font-weight: 400;
              @include transition(all 0.3s ease);
              strong {
                font-weight: 600;
              }
              &:hover {
                color: $color-turquoise;
              }
            }
          }
        }
      }
      .right-col {
        ul {
          display: flex;
          li {
            margin-left: 33px;
            a {
              color: #999;
              font-size: 13px;
              font-weight: 400;
              @include transition(all 0.3s ease);
              cursor: pointer;
              strong {
                font-weight: 600;
              }
              &:hover {
                color: $color-turquoise;
              }
            }
            &.select-lang {
              position: relative;
              & > a {
                padding-right: 19px;
                background: url(../img/icons/arrow-down.png) no-repeat right center;
              }
            }
          }
        }
      }
    }
  }
  .header-middle {
    @include transition(all 0.7s ease);
    .menu-sticky {
      display: none;
    }
    .inside {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 88px;
      .left-col {
        position: relative;
        a.logo {
          display: block;
          width: 176px;
          height: 73px;
          background: url(../img/logo.png) no-repeat 0 0;
          background-size: 100% auto;
          text-indent: -9999px;
          @include transition(all 0.7s ease);
        }
        .behind {
          position: absolute;
          top: 0;
          right: -29px;
          z-index: 9;
          width: 55px;
          height: 39px;
          background: url(../img/ua-flag-2.png) no-repeat 0 0;
        }
      }
      .right-col {
        display: flex;
        align-items: center;
        .form-place {
          margin-right: 10px;
          .form-search-top {
            display: flex;
          }
          input[type="text"] {
            width: 560px;
            padding: 0 23px;
            height: 46px;
            border-width: 1px 0 1px 1px;
            border-style: solid;
            border-color: #ebebeb;
            background-color: #fff;
            color: #999;
            font-size: 14px;
            font-weight: 300;
            line-height: 16px;
            -webkit-border-top-left-radius: 3px;
            -webkit-border-bottom-left-radius: 3px;
            -moz-border-radius-topleft: 3px;
            -moz-border-radius-bottomleft: 3px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            outline: none;
          }
          a.search-loupe {
            display: block;
            width: 70px;
            height: 46px;
            background: #1cc0a0 url(../img/icons/loupe.png) no-repeat center center;
            -webkit-border-top-right-radius: 3px;
            -webkit-border-bottom-right-radius: 3px;
            -moz-border-radius-topright: 3px;
            -moz-border-radius-bottomright: 3px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            text-indent: -9999px;
            @include transition(all 0.4s ease);
            cursor: pointer;
            &:hover {
              background: #00a887 url(../img/icons/loupe.png) no-repeat center center;
            }
          }
        }
        .actions-shop {
          display: flex;
          align-items: center;
          div {
            margin-left: 38px;
            height: 46px;
            @include transition(all 0.4s ease);
            &:first-child {
              margin-left: 0;
            }
            a {
              display: block;
              height: 100%;
              padding-top: 29px;
              color: #999;
              font-size: 12px;
              font-weight: 400;
              @include transition(all 0.4s ease);
              cursor: pointer;
              &:hover {
                color: $color-links;
              }
            }
            &.search-link {
              @include transition(all 0.7s ease);
              display: none;
              opacity: 0;
              background: url(../img/icons/search.png) no-repeat center 0;
              &.close-search {
                background: url(../img/icons/close.png) no-repeat center 0;
                a {
                  &.search-text {
                    display: none;
                  }
                  &.close-text {
                    display: block;
                  }
                }
              }
              a {
                cursor: pointer;
                &.search-text {
                  display: block;
                }
                &.close-text {
                  display: none;
                }
              }
            }
            &.my-account-link {
              background: url(../img/icons/account.png) no-repeat center 0;
            }
            &.wishlist-link {
              background: url(../img/icons/wishlist.png) no-repeat center 0;
            }
            &.compare-link {
              background: url(../img/icons/compare-top.png) no-repeat center 0;
            }
            &.minicart-link {
              background: url(../img/icons/minicart.png) no-repeat center 0;
              position: relative;
              .counter-number {
                position: absolute;
                top: -6px;
                right: -3px;
                padding: 3px 5px;
                min-width: 17px;
                text-align: center;
                line-height: 11px;
                color: #fff;
                font-weight: 500;
                font-size: 11px;
                background: #1cc0a0;
                @include border-radius(50%);
              }
            }
          }
        }
      }
    }
  }
  .header-bottom {
    .inside {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 57px;
      border-top: 1px solid #ebebeb;
      .left-col {
        display: flex;
        align-items: center;
      }
      .right-col {
        display: flex;
        align-items: center;
        .social {
          display: flex;
          align-items: center;
          a {
            display: block;
            margin-left: 21px;
            width: 24px;
            height: 24px;
            text-indent: -9999px;
            &:first-child {
              margin-left: 0;
            }
            &.fb {
              background: url(../img/icons/fb.png) no-repeat 0 0;
            }
            &.insta {
              background: url(../img/icons/insta.png) no-repeat 0 0;
            }
          }
        }
        .call-us {
          display: flex;
          align-items: center;
          margin-left: 47px;
          height: 29px;
          background: url(../img/icons/phone.png) no-repeat 34px center;
          &:before {
            content: '';
            display: block;
            width: 1px;
            height: 16px;
            background: #ccc;
            margin-right: 77px;
          }
          p {
            margin: 0;
            color: #797979;
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            span {
              color: #1cc0a0;
              font-size: 12px;
              text-transform: uppercase;
            }
            a {
              color: #797979;
            }
          }
        }
      }
    }
  }
}
#Header.sticky {
  height: 88px;
  .header-top {
    visibility: hidden;
    margin-top: -40px;
  }
  .header-middle {
    .menu-sticky {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }
    .inside {
      .left-col {
        a.logo {
          width: 134px;
          height: 56px;
        }
      }
      .right-col {
        position: relative;
        .form-place {
          display: none;
          &.active {
            display: block;
            position: absolute;
            top: 80px;
            right: 320px;
            z-index: 9;
            box-shadow: 0 0 10px rgba(14, 12, 16, 0.2);
            input[type="text"] {
              width: 710px;
            }
          }
        }
        .actions-shop div.search-link {
          display: block;
          opacity: 1;
        }
      }
    }
  }
  .header-bottom {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  #menu > ul > li {
    .level-links {
      top: 88px;
    }
    &.submenu:after {
      bottom: -15px;
    }
  }
}

#menu {
  & > ul {
    display: flex;
    & > li {
      position: relative;
      font-size: 17px;
      font-weight: 500;
      text-transform: uppercase;
      & > a {
        display: block;
        padding: 0 16px;
        line-height: 57px;
        color: #222;
        @include transition(all 0.3s ease);
        &:hover {
          color: #999;
          @include transition(all 0.3s ease);
        }
      }
      &.new-active > a {
        color: #17c7be;
        @include transition(all 0.3s ease);
        &:after {
          content: '';
          display: block;
          margin-top: -16px;
          width: 100%;
          height: 1px;
          background: #17c7be;
          @include transition(all 0.3s ease);
        }
      }
      &:hover > a {
        color: #999;
        &:after {
          content: '';
          display: block;
          margin-top: -16px;
          width: 100%;
          height: 1px;
          background: #999;
          @include transition(all 0.3s ease);
        }
      }
      .level-links {
        visibility: hidden;
        opacity: 0;
        position: fixed;
        top: 185px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 9;
        width: 1400px;
        padding: 35px 60px;
        box-shadow: 0 8px 10px rgba(14, 12, 16, 0.2);
        background: #fff url(../img/shadow.png) repeat-x 0 0;
        @include transition(all 0.3s ease);
        .level-links__inside {
          display: flex;
          .submenu-links {
            width: 240px;
            padding-right: 15px;
            &.big {
              width: 312px;
            }
            h5 {
              margin-bottom: 8px;
              color: #b7b8b8;
              font-size: 15px;
              font-weight: 500;
            }
            ul {
              li {
                font-size: 14px;
                line-height: 18px;
                font-weight: 300;
                text-transform: capitalize;
                a {
                  display: block;
                  padding: 8px 0;
                  color: #333;
                  &:hover {
                    color: $color-links;
                  }
                }
              }
            }
          }
          .submenu-photo {
            height: 309px;
            overflow: hidden;
            &.narrow {
              width: 207px;
              a .desc {
                top: 70px;
                left: 0;
              }
            }
            &.wide {
              width: 496px;
              margin-left: 25px;
              a .desc {
                bottom: 27px;
                right: 0;
              }
            }
            a {
              display: block;
              position: relative;
              .photo {
                img {
                  max-width: 100%;
                }
              }
              .desc {
                position: absolute;
                padding: 9px 22px 11px;
                background: #fff;
                @include transition(all 0.3s ease);
                h4 {
                  color: #222;
                  font-family: 'Playfair Display', serif;
                  font-size: 19px;
                  text-transform: none;
                  @include transition(all 0.3s ease);
                  &:after {
                    content: '';
                    display: block;
                    margin-top: 1px;
                    width: 100%;
                    height: 1px;
                    background: #909090;
                    @include transition(all 0.3s ease);
                  }
                }
              }
              &:hover {
                .desc {
                  background: $color-links;
                  h4 {
                    color: #fff;
                    &:after {
                      background: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &:hover {
        .level-links {
          visibility: visible;
          opacity: 1;
        }
      }
      &.submenu {
        &:after {
          visibility: hidden;
          opacity: 0;
          content: "";
          position: absolute;
          bottom: 0px;
          left: 0;
          right: 0;
          margin: 0 auto;
          display: block;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom-color: #e1e1e1;
        }
        &:hover {
          &:after {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

#nav-icon {
  margin: 0 8px 0 18px;
}

/*****************************************************************************
Content
*****************************************************************************/
#Content {
  padding-top: 185px;
}

#main-slider {
  position: relative;
  .slider-main {
    .slide {
      position: relative;
      .photo {
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
        }
      }
      .content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 1400px;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .desc {
        h3 {
          font-family: 'Playfair Display', serif;
          font-size: 90px;
          line-height: 90px;
          font-weight: 700;
          color: #fff;
          text-shadow: 0 0 5px rgba(24,24,24,0.3);
        }
        h2 {
          font-size: 154px;
          line-height: 140px;
          font-weight: 700;
          -webkit-text-stroke-width: 3px;
          -webkit-text-stroke-color: #fff;
          -webkit-text-fill-color: transparent;
        }
        p {
          margin: 0;
          color: #066964;
          font-size: 27px;
          line-height: 27px;
          font-weight: 300;
        }
      }
    }
  }
  .bx-controls {
    position: absolute;
    bottom: 130px;
    left: 0;
    right: 0;
    z-index: 88;
    width: 44px;
    margin: 0 auto;
    .bx-pager {
      display: flex;
      flex-direction: column;
      align-items: end;
      margin-left: 720px;
      .bx-pager-item {
        .bx-pager-link {
          display: block;
          margin-bottom: 9px;
          width: 22px;
          height: 3px;
          background-color: rgba(255,255,255,0.5);
          text-indent: -9999px;
          @include border-radius(1px);
          @include transition(all 0.3s ease);
          &.active {
            width: 44px;
            background-color: rgba(255,255,255,1);
          }
        }
      }
    }
  }
}

#services-home {
  position: relative;
  margin-top: -119px;
  z-index: 99;
  .services-home__content {
    position: relative;
    height: 119px;
    border-top: 1px solid #fff;
    .active-item {
      position: absolute;
      left: 0;
      top: -2px;
      width: 203px;
      height: 3px;
      background-color: $color-turquoise;
      @include transition(all 0.5s ease);
      &.position1 {
        left: 25px;
        width: 149px;
      }
      &.position2 {
        left: 224px;
        width: 137px
      }
      &.position3 {
        left: 412px;
        width: 85px
      }
      &.position4 {
        left: 550px;
        width: 150px;
      }
      &.position5 {
        left: 750px;
        width: 105px;
      }
      &.position6 {
        left: 905px;
        width: 106px;
      }
      &.position7 {
        left: 1060px;
        width: 132px;
      }
      &.position8 {
        left: 1242px;
        width: 133px;
      }
    }
    .items {
      display: flex;
      justify-content: space-around;
      height: 100%;
    }
    .item {
      height: 100%;
      @include transition(all 0.3s ease);
      a {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        color: #fff;
        text-transform: uppercase;
        h6 {
          font-size: 15px;
          font-weight: 300;
        }
        h5 {
          font-size: 21px;
          font-weight: 600;
          text-shadow: 0 0 8px rgba(24,24,24,0.5);
        }
      }
    }
  }
}

#bestsellers-home {
    margin: 59px 0 60px;
    .products-slider {
      margin-top: 45px;
    }
}

#new-products-home {
  margin-bottom: 90px;
  .products-slider {
    margin-top: 45px;
  }
}

#specials-home {
  margin-bottom: 82px;
  padding: 90px 0;
  background: #f5f5f5;
  .inside {
    display: flex;
    height: 498px;
    .left-col {
      position: relative;
      margin-right: 31px;
      padding: 59px 0 0 53px;
      width: 881px;
      background: #fff;
      box-shadow: 0 10px 30px rgba(24, 24, 24, 0.1);
      .discount {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 99;
        padding: 9px 0 8px 17px;
        width: 112px;
        background: #e61313;
        p {
          margin: 0;
          color: #fff;
          &.text {
            font-size: 17px;
            line-height: 17px;
            font-weight: 300;
          }
          &.number {
            font-size: 24px;
            line-height: 24px;
            font-weight: 700;
          }
        }
      }
      .photo-product {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 540px;
        img {
          display: block;
          max-width: 100%;
        }
      }
      h6 {
        margin-bottom: 1px;
        color: #b3b4b4;
        font-size: 17px;
        line-height: 21px;
        font-weight: 300;
        letter-spacing: 0.25px;
      }
      h3 {
        margin-bottom: 46px;
        color: $color-links;
        font-family: 'Playfair Display', serif;
        font-size: 48px;
        line-height: 48px;
        font-weight: 700;
      }
      h5 {
        margin-bottom: 7px;
        color: #7f7e7e;
        font-size: 17px;
        line-height: 21px;
        font-weight: 600;
      }
      p.price {
        margin-bottom: 31px;
        letter-spacing: 0.25px;
        color: #333;
        font-size: 17px;
        line-height: 21px;
        font-weight: 400;
        strong {
          font-size: 30px;
          font-weight: 700;
        }
      }
      .time {
        display: flex;
        margin-bottom: 46px;
        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 72px;
          height: 93px;
          margin-right: 22px;
          background: $color-links;
          @include border-radius(4px);
          &:last-child {
            margin-right: 0;
          }
          p {
            color: #fff;
            text-align: center;
            &.number {
              margin-bottom: 5px;
              font-size: 30px;
              line-height: 30px;
              font-weight: 700;
            }
            &.text {
              margin: 0;
              font-size: 12px;
              line-height: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }
    .right-col {
      position: relative;
      width: 365px;
      padding-top: 82px;
      background: url(../img/strefa-okazji.jpg) no-repeat 0 0;
      text-align: center;
      h3 {
        display: inline-block;
        margin-bottom: 16px;
        color: #fff;
        font-family: 'Playfair Display', serif;
        font-size: 43px;
        line-height: 43px;
        font-weight: 900;
        &:after {
          content: '';
          display: block;
          margin: 19px auto 0;
          width: 177px;
          height: 3px;
          background: #fff;
          @include transition(all 0.4s ease);
        }
      }
      p {
        margin: 0;
        color: #fff;
        font-size: 15px;
        line-height: 19px;
        font-weight: 300;
      }
      .bottom {
        position: absolute;
        bottom: 46px;
        left: 0;
        right: 0;
      }
      .inverted {
        position: absolute;
        left: 190px;
        top: 163px;
        color: #ececec;
        font-size: 72px;
        line-height: 72px;
        font-weight: 700;
        text-transform: uppercase;
        @include transform(rotate(90deg));
      }
    }
  }
}

#filter-home {
  margin-bottom: 130px;
  h4 {
    display: inline-block;
    margin-top: 32px;
    color: #222;
    font-size: 19px;
    font-weight: 400;
    &:after {
      content: '';
      display: block;
      margin-top: 11px;
      width: calc(100% + 52px);
      height: 1px;
      background: #aaa;
    }
  }
  .filter-home-inside {
    display: flex;
    flex-wrap: wrap;
    margin: 38px 0 34px;
    .item {
      position: relative;
      margin: 0 17px 30px 0;
      &:last-child {
        margin-right: 0;
      }
      .name {
        position: absolute;
        top: -7px;
        left: 7px;
        z-index: 9;
        padding: 0 3px;
        background-color: #fff;
        color: #767676;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        line-height: 15px;
      }
    }
  }
  .text-line {
    .line {
      height: 1px;
    }
    .btn-search {
      display: inline-block;
      margin: 0 5px;
      padding: 0 75px;
      line-height: 50px;
      color: #fff;
      background-color: #07aba3;
      font-size: 17px;
      font-weight: 500;
      text-transform: uppercase;
      @include transition(all 0.3s ease);
      cursor: pointer;
      &:hover, &:focus {
        color: #fff;
        outline: none;
        background-color: #00968f;
      }
    }
  }
}

#category-main {
  position: relative;
  height: 1016px;
  margin-bottom: 117px;
  padding-top: 98px;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    content: '';
    display: block;
    width: 512px;
    height: 1016px;
    background: #f5f5f5;
  }
  &:after {
    position: absolute;
    top: -80px;
    right: 0;
    z-index: 9;
    content: '';
    display: block;
    width: 327px;
    height: 337px;
    background: url(../img/dashes-bg.png) no-repeat 0 0;
  }
  .inside {
    position: relative;
    z-index: 10;
    margin-bottom: 42px;
    .photo {
      img {
        display: block;
        max-width: 100%;
      }
    }
    .desc {
      position: absolute;
      top: 187px;
      left: -121px;
      width: 595px;
      height: 440px;
      padding: 86px 121px 0;
      background: #fff;
      box-shadow: 0 0 8px rgba(24, 24, 24, 0.1);
      h6 {
        margin-bottom: 3px;
        color: #b0afaf;
        font-size: 17px;
        line-height: 21px;
        font-weight: 300;
        text-transform: uppercase;
      }
      h3 {
        margin-bottom: 22px;
        color: $color-turquoise;
        font-family: 'Playfair Display', serif;
        font-size: 38px;
        line-height: 38px;
        font-weight: 700;
        a {
          color: $color-turquoise;
          @include transition(all 0.4s ease);
          &:hover {
            text-decoration: underline;
          }
        }
      }
      p {
        margin: 0;
        color: #031a24;
        font-size: 21px;
        line-height: 38px;
        font-weight: 300;
      }
    }
  }
  a.link {
    margin-left: 337px;
  }
}

#inspirations-home {
  margin-bottom: 96px;
  .insta-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 30px;
    height: 45px;
    padding-left: 62px;
    background: url(../img/instagram-logo.png) no-repeat 0 0;
    p {
      margin: 0;
      color: #b0afaf;
      font-size: 15px;
      line-height: 15px;
      font-weight: 300;
      a {
        color: #b0afaf;
      }
      &.big {
        font-size: 21px;
        line-height: 21px;
      }
      strong {
        font-weight: 600;
        color: #8f8f8f;
      }
    }
  }
  .inside {
    position: relative;
    a.insta-photo {
      position: relative;
      display: block;
      background: #000;
      @include transition(all 0.4s ease);
      img {
        display: block;
        max-width: 100%;
        @include transition(all 0.4s ease);
      }
      .effect {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100px;
        height: 100px;
        background: url(../img/instagram-logo-white.png) no-repeat 0 0;
        background-size: 100%;
        @include transition(all 0.4s ease);
      }
      &:hover {
        img {
          opacity: 0.6;
        }
        .effect {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .few-photos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;
      width: 687px;
      a.insta-photo {
        width: 330px;
        height: 330px;
        overflow: hidden;
        .effect {
          width: 50px;
          height: 50px;
        }
      }
    }
    .one-photo {
      width: 687px;
      a.insta-photo {
        width: 687px;
        height: 687px;
        overflow: hidden;
      }
    }
    .items {
      display: flex;
      justify-content: space-between;
      margin-top: 86px;
    }
    .additional-photos {
      a.insta-photo {
        width: 330px;
        .effect {
          width: 50px;
          height: 50px;
        }
      }
      a.left-side {
        position: absolute;
        bottom: 0;
        left: -360px;
      }
      a.right-side {
        position: absolute;
        bottom: 357px;
        right: -360px;
      }
    }
  }
}

#blog-home {
  margin-bottom: 53px;
  .inside {
    padding-top: 50px;
    border-top: 2px solid #e7e7e7;
    h2 {
      margin-bottom: 39px;
      text-align: center;
    }
    .items {
      display: flex;
      justify-content: space-between;
      margin-bottom: 38px;
      .item {
        width: 453px;
        a {
          display: block;
          .photo {
            height: 301px;
            overflow: hidden;
            img {
              margin: 0 auto;
              display: block;
              max-width: 100%;
              @include transition(all 0.4s ease);
            }
          }
          .desc {
            padding: 21px 54px 0;
            time {
              display: block;
              margin-bottom: 8px;
              color: #c3c6c8;
              font-size: 16px;
              font-weight: 400;
              text-align: center;
              @include transition(all 0.4s ease);
            }
            h3 {
              color: #303235;
              font-size: 27px;
              font-family: 'Playfair Display', serif;
              text-align: center;
              @include transition(all 0.4s ease);
            }
          }
          &:hover {
            .photo {
              img {
                @include transform(scale(1.1));
                @include transition(all 0.4s ease);
              }
            }
            .desc {
              time {
                color: #929597;
              }
              h3 {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .blog-bottom {
      text-align: center;
    }
  }
}

#category-selected {
  position: relative;
  height: 860px;
  margin-bottom: 87px;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 512px;
    height: 860px;
    background: #f5f5f5;
  }
  .container {
    position: relative;
  }
  .category-links {
    position: absolute;
    top: 73px;
    right: 0;
    z-index: 99;
    a {
      margin-left: 15px;
      display: inline-block;
      color: #ccc;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;
      text-transform: uppercase;
      @include transition(all 0.4s ease);
      &:after {
        content: '';
        display: block;
        margin-top: 10px;
        width: 100%;
        height: 2px;
        background: #e7e7e7;
        @include transition(all 0.4s ease);
      }
      &.active {
        color: #353535;
      }
      &:hover {
        color: #353535;
      }
    }
  }
  .inside {
    &.active {
      display: flex;
    }
    display: none;
    position: relative;
    padding-top: 57px;
    .left-col {
      width: 50%;
      h2 {
        margin-bottom: 30px;
      }
      .photo {
        margin-left: -251px;
        margin-bottom: 42px;
        width: 907px;
        height: 619px;
        img {
          display: block;
          max-width: 100%;
        }
      }
      a.link {
        margin-left: 337px;
      }
    }
    .right-col {
      width: 50%;
      padding: 128px 76px 0 56px;
      h6 {
        margin-bottom: 25px;
        color: #b0afaf;
        font-size: 17px;
        line-height: 24px;
        font-weight: 300;
        text-transform: uppercase;
      }
      p {
        margin-bottom: 25px;
        color: #222;
        font-size: 19px;
        line-height: 36px;
        font-weight: 300;
        a {
          display: inline-block;
          color: #222;
          font-weight: 400;
          @include transition(all 0.4s ease);
          &:after {
            content: '';
            display: block;
            margin-top: -6px;
            width: 100%;
            height: 1px;
            background: #222;
            @include transition(all 0.4s ease);
          }
          &:hover {
            color: $color-links;
            &:after {
              background: $color-links;
            }
          }
        }
      }
    }
  }
}

#delivery-info {
  padding: 64px 0 28px;
  background-color: #f1f1f1;
  .inside {
    .items {
      display: flex;
      justify-content: space-between;
      .item {
        padding-left: 64px;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        &.i1 {
          background: url(../img/delivery1.jpg) no-repeat 0 0;
        }
        &.i2 {
          background: url(../img/delivery2.jpg) no-repeat 0 0;
        }
        &.i3 {
          background: url(../img/delivery3.jpg) no-repeat 0 0;
        }
        h5 {
          margin-bottom: 4px;
          color: #444;
          font-size: 21px;
          font-weight: 500;
          a {
            color: #444;
            @include transition(all 0.4s ease);
            &:hover {
              text-decoration: underline;
            }
          }
        }
        p {
          margin: 0;
          color: #a1a1a1;
          font-size: 15px;
          line-height: 19px;
          font-weight: 300;
        }
      }
    }
  }
}

#company-home {
  margin: 68px 0 49px;
  .inside {
    padding-left: 226px;
    background: url(../img/logo-company.png) no-repeat 0 0;
    p {
      margin: 0;
      color: #707070;
      font-size: 14px;
      font-weight: 300;
      a {
        display: inline-block;
        color: #2b2929;
        font-weight: 400;
        @include transition(all 0.4s ease);
        &:after {
          content: '';
          display: block;
          margin-top: -2px;
          width: 100%;
          height: 1px;
          background: #2b2929;
          @include transition(all 0.4s ease);
        }
        &:hover {
          color: $color-links;
          &:after {
            background: $color-links;
          }
        }
      }
    }
  }
}

#shipping-payment {
  margin-bottom: 35px;
  .inside {
    display: flex;
    min-height: 172px;
    .left-col {
      width: 671px;
      background: url(../img/slash-big.png) no-repeat right 0;
      p {
        margin-bottom: 40px;
      }
    }
    .right-col {
      padding-left: 100px;
      flex: 1;
    }
    p {
      margin-top: 20px;
      color: #807f7f;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      a {
        display: inline-block;
        color: #2b2929;
        font-weight: 500;
        @include transition(all 0.4s ease);
        &:after {
          content: '';
          display: block;
          margin-top: -2px;
          width: 100%;
          height: 1px;
          background: #2b2929;
          @include transition(all 0.4s ease);
        }
        &:hover {
          color: $color-links;
          &:after {
            background: $color-links;
          }
        }
      }
    }
    .logos {
      display: flex;
      align-items: center;
      .photo {
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        img {
          display: block;
          max-width: 100%;
        }
      }
    }
  }
}

.products-slider {
  margin-bottom: 20px;
  .item {
    a {
      position: relative;
      display: block;
      margin: 0 33px 0 0;
      @include transition(all 0.4s ease);
      .photo {
        display: flex;
        align-items: center;
        height: 295px;
        margin-bottom: 15px;
        img {
          display: block;
          margin: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .desc {
        text-align: center;
        h5 {
          margin-bottom: 10px;
          color: #b0afaf;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          text-transform: uppercase;
          @include transition(all 0.4s ease);
        }
        p.price {
          margin: 0;
          color: #3a3a3a;
          font-size: 18px;
          line-height: 22px;
          font-weight: 300;
          &.price-changed {
            font-size: 17px;
          }
          strong {
            font-size: 21px;
            font-weight: 400;
          }
          s {
            font-size: 17px;
            font-weight: 400;
          }
          .red {
            font-size: 17px;
            color: #e61313;
            font-weight: 400;
          }
        }
      }
      .info {
        position: absolute;
        top: 24px;
        left: 0;
        font-size: 11px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
        .turquoise {
          position: relative;
          margin-bottom: 5px;
          padding: 0 7px 0 10px;
          background: $color-turquoise;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: -9px;
            border-top: 24px solid $color-turquoise;
	          border-right: 9px solid transparent;
          }
        }
        .red {
          position: relative;
          margin-bottom: 5px;
          padding: 0 7px 0 10px;
          background: #e61313;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: -9px;
            border-top: 24px solid #e61313;
            border-right: 9px solid transparent;
          }
        }
      }
      .circles {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 27px;
        right: 19px;
        @include transition(all 0.4s ease);
        div {
          display: block;
          width: 35px;
          height: 35px;
          margin-bottom: 17px;
          cursor: pointer;
          background: #fff;
          text-indent: -9999px;
          @include border-radius(50%);
          box-shadow: 0 0 3px rgba(24, 24, 24, 0.5);
          &.favorite {
            background: #fff url(../img/icons/favorite.png) no-repeat center center;
            &:hover {
              background: $color-turquoise url(../img/icons/favorite-white.png) no-repeat center center;
            }
          }
          &.compare {
            background: #fff url(../img/icons/compare.png) no-repeat center center;
            &:hover {
              background: $color-turquoise url(../img/icons/compare-white.png) no-repeat center center;
            }
          }
        }
      }
      &:hover {
        .desc {
          h5 {
            color: $color-links;
            text-decoration: underline;
          }
        }
        .circles {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    top: 135px;
    display: block;
    width: 13px;
    height: 24px;
    border: 0;
    text-indent: -9999px;
    outline: none;
    &.slick-prev {
      left: -46px;
      background: url(../img/icons/arrow-left-slider.png) no-repeat 0 0;
      @include transition(all 0.3s ease);
      &:hover {
        background: url(../img/icons/arrow-left-slider-hover.png) no-repeat 0 0;
      }
    }
    &.slick-next {
      right: -46px;
      background: url(../img/icons/arrow-right-slider.png) no-repeat 0 0;
      @include transition(all 0.3s ease);
      &:hover {
        background: url(../img/icons/arrow-right-slider-hover.png) no-repeat 0 0;
      }
    }
  }
}


/*****************************************************************************
Footer
*****************************************************************************/
#Footer {
  background-color: #222;
  .top-footer {
    padding: 62px 0 83px;
    position: relative;
    .partner {
      position: absolute;
      bottom: -28px;
      left: 0;
      right: 0;
      a.mall {
        margin: 0 auto;
        display: block;
        width: 156px;
        height: 57px;
        background: url(../img/mall-partner.png) no-repeat 0 0;
      }
    }
    .inside {
      display: flex;
      justify-content: space-between;
    }
    .left-col {
      h3 {
        margin-bottom: 21px;
        color: #fff;
        font-size: 26px;
        line-height: 24px;
        font-weight: 400;
        strong {
          font-weight: 700;
        }
      }
      h5 {
        color: #fff;
        font-size: 16px;
        line-height: 30px;
        font-weight: 600;
      }
      p {
        margin-bottom: 24px;
        color: #999;
        font-size: 15px;
        line-height: 30px;
        font-weight: 300;
      }
      h4 {
        color: #fff;
        font-size: 21px;
        line-height: 30px;
        font-weight: 500;
        a {
          color: #fff;
        }
        a.email {
          position: relative;
          color: #07aba3;
          @include transition(all 0.3s ease);
          &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            right: 0;
            display: block;
            width: 100%;
            height: 2px;
            background-color: #146763;
            @include transition(all 0.3s ease);
          }
          &:hover {
            &:after {
              opacity:0;
            }
          }
        }
      }
    }
    .right-col {
      display: flex;
      .item {
        padding-right: 65px;
        &:last-child {
          padding-right: 0;
        }
        h5 {
          margin-bottom: 10px;
          color: #fff;
          font-size: 16px;
          line-height: 30px;
          font-weight: 600;
        }
        ul {
          li {
            a {
              color: #999;
              font-size: 15px;
              line-height: 30px;
              font-weight: 300;
              @include transition(all 0.3s ease);
              &:hover {
                color: $color-turquoise;
              }
            }
          }
        }
      }
    }
  }
  .category-links {
    .inside {
      padding: 56px 0 42px;
      border-top: 1px solid #444;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 20%;
          a {
            display: block;
            padding: 5px 20px 5px 0;
            font-size: 15px;
            line-height: 19px;
            color: #dfe1e1;
            @include transition(all 0.3s ease);
            &:hover {
              color: $color-turquoise;
            }
          }
          &:nth-child(5n) a {
            padding-right: 0;
          }
        }
      }
    }
  }
  .signature {
    p {
      margin: 0;
      color: #999;
      font-size: 15px;
      font-weight: 300;
    }
    .inside {
      padding: 13px 0 54px;
      border-top: 1px solid #444;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      a {
        color:#fff;
        font-weight: 500;
        &:hover {
          text-decoration: underline;
        }
      }
      .space {
        display: inline-block;
        padding: 0 9px 0 10px;
      }
    }
  }
}

/*****************************************************************************
Subpages
*****************************************************************************/
.subpage-breadcrumb {
  margin: 20px 0 16px;
  ol {
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      align-items: center;
      color: #999;
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
      &:after {
        content: '';
        margin: 0 13px 0 16px;
        display: block;
        width: 5px;
        height: 9px;
        background: url(../img/icons/arrow-right-small.png) no-repeat 0 0;
      }
      &:last-child:after {
        display: none;
      }
      a {
        display: block;
        color: #999;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.products-list__subpage {
  .item {
    a {
      position: relative;
      display: block;
      @include transition(all 0.4s ease);
      .photo {
        display: flex;
        align-items: center;
        height: 295px;
        margin-bottom: 15px;
        img {
          display: block;
          margin: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .desc {
        text-align: center;
        h5 {
          margin-bottom: 10px;
          color: #b0afaf;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          text-transform: uppercase;
          @include transition(all 0.4s ease);
        }
        p.price {
          margin: 0;
          color: #3a3a3a;
          font-size: 18px;
          line-height: 22px;
          font-weight: 300;
          &.price-changed {
            font-size: 17px;
          }
          strong {
            font-size: 21px;
            font-weight: 400;
          }
          s {
            font-size: 17px;
            font-weight: 400;
          }
          .red {
            font-size: 17px;
            color: #e61313;
            font-weight: 400;
          }
        }
      }
      .info {
        position: absolute;
        top: 24px;
        left: 0;
        font-size: 11px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
        .turquoise {
          position: relative;
          margin-bottom: 5px;
          padding: 0 7px 0 10px;
          background: $color-turquoise;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: -9px;
            border-top: 24px solid $color-turquoise;
            border-right: 9px solid transparent;
          }
        }
        .red {
          position: relative;
          margin-bottom: 5px;
          padding: 0 7px 0 10px;
          background: #e61313;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: -9px;
            border-top: 24px solid #e61313;
            border-right: 9px solid transparent;
          }
        }
      }
      .circles {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 27px;
        right: 19px;
        @include transition(all 0.4s ease);
        div {
          display: block;
          width: 35px;
          height: 35px;
          margin-bottom: 17px;
          cursor: pointer;
          background: #fff;
          text-indent: -9999px;
          @include border-radius(50%);
          box-shadow: 0 0 3px rgba(24, 24, 24, 0.5);
          &.favorite {
            background: #fff url(../img/icons/favorite.png) no-repeat center center;
            &:hover {
              background: $color-turquoise url(../img/icons/favorite-white.png) no-repeat center center;
            }
          }
          &.compare {
            background: #fff url(../img/icons/compare.png) no-repeat center center;
            &:hover {
              background: $color-turquoise url(../img/icons/compare-white.png) no-repeat center center;
            }
          }
        }
      }
      &:hover {
        .desc {
          h5 {
            color: $color-links;
            text-decoration: underline;
          }
        }
        .circles {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.shopping-cart__subpage {
  margin: 66px 0 38px;
  .inside {
    display: flex;
    justify-content: space-between;
    .left-col {
      flex: 1;
      margin-right: 40px;
      .head {
        display: flex;
        margin-bottom: 23px;
        h3 {
          width: 33.3%;
          margin-right: 10px;
          padding: 5px 0 7px;
          border-bottom: 2px solid #e7e7e7;
          color: #e7e7e7;
          font-size: 15px;
          line-height: 17px;
          font-weight: 500;
          &:last-child {
            margin-right: 0;
          }
          a {
            color: #e7e7e7;
          }
          &.active {
            color: $color-turquoise;
            border-bottom: 2px solid $color-turquoise;
            a {
              color: $color-turquoise;
            }
          }
        }
      }
      .under-cart {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        ul.simply-link {
          display: flex;
          width: 60%;
          li {
            margin-right: 23px;
            a {
              display: block;
              color: #000;
              font-size: 13px;
              line-height: 19px;
              font-weight: 300;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .whole-value {
          width: 40%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          p {
            margin: 0;
            &.text {
              color: #000;
              font-size: 15px;
              line-height: 19px;
              font-weight: 500;
              .grey {
                color: #c4c4c4;
                font-size: 10px;
                font-weight: 600;
              }
            }
            &.number {
              margin-left: 108px;
              color: #000;
              font-size: 19px;
              line-height: 21px;
              font-weight: 600;
            }
          }
        }
        .buttons {
          margin-top: 28px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          p.info  {
            margin: 0 32px 0 0;
            color: #b2b2b2;
            font-size: 13px;
            line-height: 15px;
            font-weight: 300;
            .red {
              color: #e61313;
            }
          }
          .btn {
            min-width: 163px;
          }
          .btn-transparent-grey {
            margin-right: 9px;
          }
        }
      }
    }
    .right-col {
      width: 443px;
      margin-top: 20px;
      h4 {
        margin-bottom: 30px;
        color: #222;
        font-size: 15px;
        line-height: 19px;
        font-weight: 500;
      }
      .products-list__subpage {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
          width: 205px;
          margin-bottom: 30px;
        }
      }
    }
  }
  h4.h4-cart {
    margin-top: 33px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e7e7e7;
    color: #222;
    font-size: 19px;
    line-height: 23px;
    font-weight: 500;
  }
}

.cart-table {
  margin-bottom: 30px;
  .cart-row.cart-row-head {
    padding: 6px 0;
    background-color: #eeeff1;
    border-bottom: 0;
    p {
      margin: 0;
      color: #222;
      font-size: 15px;
      line-height: 19px;
    }
  }
  .cart-row {
    position: relative;
    display: flex;
    align-items: center;
    padding: 32px 0 30px;
    border-bottom: 1px solid #ddd;
    .cart-width-1 {
      width: 9%;
      padding: 0 20px;
    }
    .cart-width-2 {
      width: 52%;
      padding-right: 10px;
    }
    .cart-width-3 {
      width: 22%;
      padding-right: 10px;
    }
    .cart-width-4 {
      width: 17%;
    }
    p {
      margin-bottom: 0;
    }
    .name-product {
      display: flex;
      align-items: center;
      .photo {
        width: 57px;
        margin-right: 20px;
        a {
          display: block;
          img {
            display: block;
            min-width: 100%;
            max-width: 100%;
          }
        }
      }
      .desc {
        h5 {
          color: #7f7f7f;
          font-size: 13px;
          line-height: 17px;
          font-weight: 400;
          text-transform: uppercase;
          a {
            color: #7f7f7f;
            &:hover {
              color: $color-links;
              text-decoration: underline;
            }
          }
        }
        p {
          margin: 0;
          color: #7f7f7f;
          font-size: 13px;
          line-height: 17px;
          font-weight: 400;
          span.grey {
            padding-left: 10px;
            color: #c3c3c3;
            font-size: 11px;
            font-weight: 300;
            text-transform: uppercase;
          }
        }
      }
    }
    .quantity {
      display: flex;
      p {
        margin: 0;
        color: #666;
        font-size: 14px;
        line-height: 15px;
        font-weight: 300;
        span {
          color: #222;
        }
      }
      button {
        display: block;
        width: 40px;
        padding: 0;
        background-color: #fff;
        border: 1px solid #c3c3c3;
        span {
          display: block;
          text-indent: -9999px;
        }
        &.inactive span {
          opacity: 0.5;
        }
        &.minus {
          -webkit-border-top-left-radius: 2px;
          -webkit-border-bottom-left-radius: 2px;
          -moz-border-radius-topleft: 2px;
          -moz-border-radius-bottomleft: 2px;
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
          span {
            background: url(../img/icons/minus.png) no-repeat center center;
          }
        }
        &.plus {
          -webkit-border-top-right-radius: 2px;
          -webkit-border-bottom-right-radius: 2px;
          -moz-border-radius-topright: 2px;
          -moz-border-radius-bottomright: 2px;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          span {
            background: url(../img/icons/plus.png) no-repeat center center;
          }
        }
        &.pluas {
          border: 0 !important;
        }
      }
      input.text {
        width: 63px;
        padding: 7px 0;
        background-color: #fff;
        border-width: 1px 0;
        border-style: solid;
        border-color: #c3c3c3;
        text-align: center;
        font-size: 17px;
        font-weight: 300;
        color: #3f474b;
        &::-webkit-input-placeholder { color: #3f474b !important; opacity: 1; }
        &:-moz-placeholder { color: #3f474b !important; opacity: 1; }
        &::-moz-placeholder { color: #3f474b !important; opacity: 1; }
        &:-ms-input-placeholder { color: #3f474b !important; opacity: 1; }
      }
    }
    .value {
      p {
        margin: 0;
        text-align: right;
        &.all {
          color: #000;
          font-size: 17px;
          line-height: 19px;
          font-weight: 500;
        }
        &.one {
          color: #837f7a;
          font-size: 13px;
          line-height: 15px;
          font-weight: 300;
        }
      }
    }
    .remove {
      a {
        display: block;
        width: 40px;
        height: 40px;
        background: url(../img/icons/close3.png) no-repeat 0 0;
        text-indent: -9999px;
        cursor: pointer;
      }
    }
  }
}

.shopping-cart__subpage.step-2 {
  .cart-table .cart-row .cart-width-2 {
    width: 61%;
  }
  .inside .right-col {
    position: sticky;
    top: 110px;
    align-self: start;
    padding: 42px;
    background-color: #f5f5f5;
    h5 {
      margin-bottom: 25px;
      color: #222;
      font-weight: 500;
      font-size: 19px;
      line-height: 23px;
    }
    .regulations {
      margin-bottom: 40px;
      p.text-small {
        margin-bottom: 15px;
        color: #828181;
        text-align: justify;
        &.dark {
          color: #212121;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      .btn {
        width: 48%;
      }
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e7e7e7;
    h2 {
      color: #222;
      font-size: 20px;
      line-height: 23px;
      font-weight: 500;
    }
  }
}

.shopping-cart__subpage.step-3 .inside .right-col {
  align-self: auto;
}

.end-of-shopping {
  padding-top: 20px;
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
  h4.cart {
    margin-bottom: 15px;
    padding-top: 90px;
    background: url(../img/cart.png) no-repeat center 0;
    color: #222;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
  }
  p.green {
    margin-bottom: 30px;
    color: #5cb85c;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
  }
  .btn-turquoise {
    min-width: 163px;
  }
  ul.simply-link {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    li {
      a {
        display: block;
        padding: 5px 12px;
        color: #000;
        font-size: 13px;
        line-height: 19px;
        font-weight: 300;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.price-summary {
  .price-summary__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
    .txt {
      color: #828181;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }
    .number {
      color: #000;
      font-weight: 500;
      font-size: 17px;
      line-height: 19px;
    }
    &.all {
      margin-bottom: 40px;
      padding-top: 22px;
      border-top: 1px solid #adadad;
      .txt {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        span {
          color: #c4c4c4;
          font-size: 10px;
          font-weight: 600;
        }
      }
      .number {
        font-size: 21px;
        font-weight: 600;
      }
    }
  }
}

.products__subpage {
  margin-bottom: 50px;
  .inside {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    .left-col {
      width: 252px;
      margin-top: 13px;
    }
    .right-col {
      width: 1076px;
      position: relative;
      h5.text {
        margin-bottom: 5px;
        color: #9f9b9b;
        font-size: 15px;
        line-height: 17px;
        font-weight: 500;
        text-transform: uppercase;
      }
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 9px;
      }
    }
  }
  .products-list__subpage {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 240px;
      margin-bottom: 40px;
      a {
        .photo {
          height: 340px;
        }
      }
    }
  }
}

.listing-sort {
  display: flex;
  align-items: center;
  min-width: 225px;
  margin-left: 15px;
  border: 1px solid #bfbfbf;
  @include border-radius(3px);
  h5 {
    margin: 0 10px 0 15px;
    color: #999;
    font-size: 13px;
    font-weight: 400;
    line-height: 37px;
    text-align: right;
  }
  .bootstrap-select {
    .btn-light {
      background: #fff;
      border: 0;
    }
    .btn {
      padding: 11px 12px 0 0;
      height: 37px;
      font-size: 13px;
      line-height: 15px;
      font-weight: 400;
      color: $color-links;
      outline: none;
      &:focus {
        outline: none !important;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
        color: $color-links;
        background: #fff;
      }
      &:hover {
        color: $color-links;
        background: #fff;
      }
    }
    .dropdown-menu {
      //width: 225px;
      right: -1px !important;
      left: inherit !important;
      color: #999;
      font-size: 13px;
      font-weight: 400;
      box-shadow: 0 0 0 0;
      overflow: hidden;
      z-index: 10;
      & > li {
        padding: 0 15px;
        & > a {
          padding: 7px 0;
          border-bottom: 1px dotted #ddd;
          &:hover {
            background-color: #fff;
          }
        }
        &:last-child > a {
          border-bottom: 0;
        }
      }
      .inner {
        overflow: hidden !important;
      }
    }
    .dropdown-item.active, .dropdown-item:active {
      background-color: #fff;
      color: $color-links;
    }
    .dropdown-toggle {
      display: flex;
      justify-content: space-between;
      &:after {
        content: '';
        display: block;
        margin-top: 3px;
        width: 14px;
        height: 8px;
        border: 0;
        background: url(../img/icons/arrow-down-small.png) no-repeat 0 0;
      }
    }
  }
  &.nr-in {
    min-width: inherit;
    width: 64px;
    border-color: #aaa;
    @include border-radius(2px);
    .bootstrap-select {
      .btn {
        height: 35px;
        color: #222;
      }
      .dropdown-toggle .filter-option {
        padding-left: 12px;
      }
      .dropdown-menu {
        width: 64px;
      }
    }
  }
}

.button-fast-filtering {
  display: none;
  margin-bottom: 10px;
  padding: 13px 5px;
  line-height: 21px;
  background-color: #f4f4f5;
  color: $color-links;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.product-list-filtering {
  h5 {
    position: relative;
    margin-bottom: 10px;
    padding-right: 20px;
    color: #000;
    font-size: 17px;
    line-height: 21px;
    font-weight: 500;
    cursor: pointer;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 5px;
      right: 0;
      width: 14px;
      height: 8px;
      background: url(../img/icons/arrow-up-small.png) no-repeat 0 0;
    }
  }
  fieldset {
    margin-bottom: 32px;
    &.hidden {
      h5:after {
        background: url(../img/icons/arrow-down-small.png) no-repeat 0 0;
      }
      .inner {
        display: none;
      }
    }
  }
  .position-hidden {
    display: none;
    &.active {
      display: block;
    }
  }
  a.show-more {
    display: none;
    color: #a8aeae;
    font-size: 13px;
    line-height: 27px;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
    @include transition(all 0.3s ease);
    &:after {
      content: '';
      display: block;
      margin-top: -5px;
      width: 100%;
      height: 1px;
      background: #a8aeae;
      @include transition(all 0.3s ease);
    }
    &:hover {
      color: $color-links;
      &:after {
        background: $color-links;
      }
    }
    &.active {
      display: inline-block;
    }
  }
  a.show-less {
    display: none;
    color: #a8aeae;
    font-size: 13px;
    line-height: 27px;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
    @include transition(all 0.3s ease);
    &:after {
      content: '';
      display: block;
      margin-top: -5px;
      width: 100%;
      height: 1px;
      background: #a8aeae;
      @include transition(all 0.3s ease);
    }
    &:hover {
      color: $color-links;
      &:after {
        background: $color-links;
      }
    }
    &.active {
      display: inline-block;
    }
  }
  .categories {
    ul {
      li {
        margin-bottom: 10px;
        a {
          display: block;
          color: #222;
          font-size: 14px;
          line-height: 18px;
          font-weight: 300;
          cursor: pointer;
          @include transition(all 0.3s ease);
          &.active {
            color: $color-links;
            font-weight: 500;
          }
          &:hover {
            color: $color-links;
          }
        }
      }
    }
  }
}

ul.filter-checkbox {
  margin-top: 20px;
  li {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    label {
      margin: 0;
      width: 100%;
      color: #222;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2000;
        width: 100%;
        height: 26px;
        margin: -4px 0 0;
        padding: 0;
        border: 0;
        opacity: 0;
        cursor: pointer;
      }
      .new-checkbox {
        position: absolute;
        top: 0;
        left: 0;
        span.square {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          border: 1px solid #aaa;
          background-color: #fff;
          @include border-radius(2px);
        }
        span.text {
          padding-left: 29px;
          color: #222;
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
        }
        &.selected {
          span.square {
            border: 2px solid $color-links;
            background: #fff url(../img/icons/selected-checkbox.png) no-repeat center center;
          }
        }
      }
    }
    &:hover {
      label .new-checkbox span.text {
        text-decoration: underline;
      }
    }
  }
}
ul.filter-checkbox-color {
  display: flex;
  flex-wrap: wrap;
  li {
    width: 25px;
    margin: 0 17px 17px 0;
    label {
      .new-checkbox {
        span.text {
          display: none;
        }
        span.square {
          width: 25px;
          height: 25px;
          @include border-radius(50%);
          &.color-1 {
            background: $color-1;
            border-color: $color-1;
          }
          &.color-2 {
            background: $color-2;
            border-color: $color-2;
          }
          &.color-3 {
            background: $color-3;
            border-color: $color-3;
          }
          &.color-4 {
            background: $color-4;
            border-color: $color-4;
          }
          &.color-5 {
            background: $color-5;
            border-color: $color-5;
          }
          &.color-6 {
            background: $color-6;
            border-color: $color-6;
          }
          &.color-7 {
            background: $color-7;
            border-color: $color-7;
          }
          &.color-8 {
            background: $color-8;
            border-color: $color-8;
          }
          &.color-9 {
            background: $color-9;
            border-color: $color-9;
          }
          &.color-10 {
            background: $color-10;
            border-color: $color-10;
          }
          &.color-11 {
            background: $color-11;
            border-color: $color-11;
          }
          &.color-12 {
            background: $color-12;
            border-color: $color-12;
          }
        }
        &.selected:before {
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          display: block;
          width: 31px;
          height: 31px;
          @include border-radius(50%);
          background: #fff;
          border: 2px solid $color-links
        }
      }
    }
  }
}
ul.filter-checkbox-size {
  li {
    position: relative;
    cursor: pointer;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 5px;
      right: 0;
      width: 14px;
      height: 8px;
      background: url(../img/icons/arrow-down-small.png) no-repeat 0 0;
    }
    &.active:after {
      background: url(../img/icons/arrow-up-small.png) no-repeat 0 0;
    }
    &:hover {
      label .new-checkbox span.text {
        text-decoration: none;
      }
    }
    .more-size {
      display: none;
      margin-top: 36px;
      ul li {
        &:after {
          display: none;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.active .more-size {
      display: block;
    }
  }

}
.input-line {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input[type="text"] {
    width: 115px;
    padding: 10px 12px;
    height: 40px;
    border: 1px solid #aaa;
    background-color: #fff;
    color: #6f6f6f;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    @include border-radius(2px);
    &::-webkit-input-placeholder { color: #6f6f6f !important; opacity: 1; }
    &:-moz-placeholder { color: #6f6f6f !important; opacity: 1; }
    &::-moz-placeholder { color: #6f6f6f !important; opacity: 1; }
    &:-ms-input-placeholder { color: #6f6f6f !important; opacity: 1; }
    &:focus, &:hover {
      outline: 0;
    }
  }
  span {
    color: #222;
  }
}

.filtration-results {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .item {
    margin: 0 8px 8px 0;;
    .btn {
      position: relative;
      padding: 12px 43px 12px 7px;
      border: 1px solid #aaa;
      background: transparent;
      color: #222;
      font-size: 14px;
      line-height: 14px;
      font-weight: 300;
      @include border-radius(2px);
      &:after {
        content: '';
        position: absolute;
        top: 11px;
        right: 11px;
        display: block;
        width: 16px;
        height: 16px;
        background: url(../img/icons/remove-checkbox.png) no-repeat 0 0;
        background-size: 100%;
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 0;
      }
      .grey {
        color: #76787c;
      }
    }
    .remove-all {
      display: block;
      padding: 0 7px;
      color: #000;
      font-size: 13px;
      line-height: 19px;
      font-weight: 500;
      cursor: pointer;
      @include transition(all 0.3s ease);
      &:after {
        content: '';
        display: block;
        margin-top: -1px;
        width: 100%;
        height: 1px;
        background: #2b2929;
        @include transition(all 0.3s ease);
      }
      &:hover {
        color: $color-links;
        &:after {
          background: $color-links;
        }
      }
    }
  }
}

.how-many {
  position: absolute;
  top: 22px;
  right: 0;
  display: flex;
  align-items: center;
  p {
    margin: 0;
    color: #222;
    font-size: 14px;
    font-weight: 300;
  }
}

.how-many-new {
  display: flex;
  align-items: center;
  p {
    display: none;
    margin: 0 0 0 10px;
    color: #222;
    font-size: 14px;
    font-weight: 300;
  }
}

.products-slider__subpage {
  margin-bottom: 47px;
  padding: 20px 65px 8px;
  background-color: #f4f4f5;
  h3 {
    margin-bottom: 25px;
    color: #1c1c1c;
    font-family: 'Playfair Display', serif;
    font-size: 21px;
    line-height: 23px;
  }
  .products-slider {
    .item a {
      .photo {
        height: 300px;
      }
      .desc {
        h5 {
          margin-bottom: 5px;
          font-size: 13px;
        }
        p.price {
          font-size: 16px;
          strong {
            font-size: 18px;
          }
          .red, s {
            font-size: 15px;
          }
        }
      }
    }
    .slick-arrow.slick-prev {
      left: -51px;
      width: 40px;
      height: 40px;
      background: url(../img/icons/arrow-left-slider2.png) no-repeat 0 0;
      background-size: 100%;
    }
    .slick-arrow.slick-next {
      right: -51px;
      width: 40px;
      height: 40px;
      background: url(../img/icons/arrow-right-slider2.png) no-repeat 0 0;
      background-size: 100%;
    }
  }
}

.additional-text-product {
  h3 {
    margin-bottom: 25px;
    color: #222;
    font-size: 21px;
    line-height: 27px;
    font-weight: 500;
  }
  p {
    color: #222;
    font-size: 15px;
    line-height: 27px;
    font-weight: 300;
    a {
      color: #222;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }
    strong {
      font-weight: 500;
    }
  }
}

.comparison__subpage .inside {
  margin: 40px 0 60px;
}
.comparison {
  p {
    margin: 0;
    font-size: 14px;
    color: #333;
    font-weight: 300;
  }
  .comparison__header {
    display: flex;
    justify-content: space-between;
    .comparison__header--label {
      width: 180px;
      padding-top: 140px;
      text-align: right;
      p.txt {
        padding-left: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #999;
      }
    }
    .comparison__header--item {
      position: relative;
      padding: 0 2.5%;
      text-align: center;
      &.is-one {
        width: 100%;
      }
      &.is-two {
        width: 50%;
      }
      &.is-three {
        width: 33.3%;
      }
      a.comparison_itemRemove {
        position: absolute;
        top: 10px;
        right: 0;
        left: 0;
        margin: 0 auto;
        display: block;
        width: 15px;
        height: 15px;
        background: url(../img/icons/close4.png) no-repeat;
        cursor: pointer;
      }
      .product-show {
        margin: 40px 0 10px;
        .comparison__slider {
          margin: 0 auto;
          width: 154px;
          height: 219px;
          .slick-arrow {
            position: absolute;
            top: 90px;
            z-index: 99;
            display: block;
            width: 40px;
            height: 40px;
            border: 0;
            text-indent: -9999px;
            outline: none;
            &.slick-prev {
              left: -40px;
              background: url(../img/icons/arrow-left-slider2.png) no-repeat 0 0;
            }
            &.slick-next {
              right: -40px;
              background: url(../img/icons/arrow-right-slider2.png) no-repeat 0 0;
            }
          }
        }
        .photo {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          width: 154px;
          height: 219px;
          img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
          }
        }
      }
      a.comparison_itemName {
        display: block;
        margin-bottom: 10px;
        color: #b0afaf;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        text-transform: uppercase;
        word-wrap: break-word;
        &:hover {
          text-decoration: underline;
        }
      }
      .priceBox {
        margin-bottom: 20px;
        p.priceBox__old {
          color: #a5a5a5;
        }
        p.priceBox__now {
          color: #3a3a3a;
          font-size: 12px;
          line-height: 15px;
          font-weight: 300;
          strong {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .see {
        margin: 35px 0 54px;
      }
    }
  }
  .comparison__content {
    .comparison__row {
      display: flex;
      background-color: #f7f7f7;
      &:nth-child(2n) {
        background-color: #fff;
      }
      .comparison__row--label {
        width: 180px;
        text-align: right;
        p {
          padding: 20px 0 20px 5px;
          font-size: 14px;
          font-weight: 500;
          color: #999;
        }
      }
      .comparison__row--item {
        padding: 0 2.5%;
        text-align: center;
        word-wrap: break-word;
        p {
          padding: 20px 0;
        }
        &.is-one {
          width: 100%;
        }
        &.is-two {
          width: 50%;
        }
        &.is-three {
          width: 33.3%;
        }
        .btn-red {
          padding: 11px 60px;
          font-weight: 700;
        }
        ul.available-colors {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding: 5px 0;
          li {
            padding: 9px;
            a {
              display: block;
              width: 25px;
              height: 25px;
              @include border-radius(50%);
              text-indent: -999em;
            }
          }
        }
        ul.available-sizes {
          margin-bottom: 20px;
          padding-top: 8px;
          li {
            padding: 10px 0;
            color: #333;
            font-size: 14px;
            font-weight: 300;
            text-align: center;
            span.price {
              font-weight: 500;
            }
            &.not-available {
              color: #cac9c9;
              text-decoration: line-through;
            }
          }
        }
      }
      &:first-child {
        .comparison__row--label p, .comparison__row--item p {
          border-top: 0;
        }
      }
      &:last-child {
        .comparison__row--item {
          padding-bottom: 20px;
        }
      }
    }
  }
}

.blog__subpage #blog-home .inside {
  border: 0;
  padding: 0;
  h2 {
    font-size: 35px;
  }
  .items {
    flex-wrap: wrap;
    .item {
      margin-bottom: 60px;
      a {
        .desc {
          padding: 15px 40px 0;
          h3 {
            font-size: 22px;
          }
        }
      }
    }
  }
}

.map-site__subpage {
  margin-bottom: 60px;
  .text-line {
    margin-bottom: 30px;
  }
}

.inspiration__subpage #inspirations-home .inside {
  h6.h6-main {
    margin-bottom: 40px;
  }
  .items {
    margin-top: 25px;
  }
}

.customer-panel__subpage {
  margin-bottom: 40px;
  .inside {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .left-col {
      width: 444px;
      padding: 55px 47px 44px;
      background-color: #f5f5f5;
      &:after {
        content: '';
        display: block;
        margin: 60px auto 0;
        width: 128px;
        height: 48px;
        background: url(../img/chemex.png) no-repeat 0 0;
      }
      .top {
        margin-bottom: 20px;
        padding: 9px 0 9px 72px;
        background: url(../img/icons/user-panel-white.png) no-repeat 0 0;
        h5 {
          color: #222;
          font-size: 17px;
          line-height: 19px;
          font-weight: 400;
        }
        p {
          margin: 0;
          color: #94999c;
          font-size: 13px;
          font-weight: 300;
          word-wrap: break-word;
        }
      }
      .options {
        .item {
          position: relative;
          border-bottom: 1px solid #e7e7e7;
          &:last-child {
            border: 0;
          }
          a {
            display: block;
            padding: 24px 14px 25px;
            color: #222;
            font-size: 15px;
            line-height: 19px;
            font-weight: 400;
            @include transition(all 0.3s ease);
            &.active {
              color: $color-links;
            }
            &:hover {
              color: $color-links;
            }
            &.red {
              color: #e50303;
              &:hover {
                color: #e50303;
                text-decoration: underline;
              }
            }
          }
          .counter-number {
            position: absolute;
            top: 24px;
            right: 14px;
            padding: 4px 7px;
            line-height: 13px;
            color: #fff;
            font-weight: 500;
            font-size: 13px;
            background: #1cc0a0;
            @include border-radius(50%);
          }
        }
      }
    }
    .right-col {
      width: 917px;
      padding-top: 15px;
      h3 {
        margin-bottom: 30px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e7e7e7;
        color: #222;
        font-size: 20px;
        line-height: 23px;
        font-weight: 500;
      }
      p.intro {
        color: #666;
        font-size: 15px;
        line-height: 19px;
        font-weight: 500;
      }
      .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e7e7e7;
        h2 {
          color: #222;
          font-size: 20px;
          line-height: 23px;
          font-weight: 500;
        }
        p.date {
          margin: 0 0 0 20px;
          color: #999;
          font-size: 14px;
          font-weight: 300;
          time {
            color: #222;
            .hour {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

.order-list {
  margin-top: 30px;
  p {
    margin: 0;
    color: #222;
    font-size: 15px;
    line-height: 19px;
    font-weight: 400;
    .hour {
      margin-left: 20px;
    }
    &.resp {
      display: none;
    }
    a {
      position: relative;
      color: $color-links;
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;
      text-transform: uppercase;
      @include transition(all 0.3s ease);
      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        right: 0;
        display: block;
        width: 100%;
        height: 1px;
        background: $color-links;
        @include transition(all 0.3s ease);
      }
      &:hover {
        color: #222;
        &:after {
          background: #222;
        }
      }
    }
  }
  .row-head {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e7e7e7;
    p {
      color: #888;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  .row-order {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #e7e7e7;
  }
  .number {
    width: 19%;
    padding: 0 20px;
  }
  .date {
    width: 27%;
    padding: 0 20px;
  }
  .price {
    width: 20%;
    padding: 0 20px;
  }
  .more {
    width: 34%;
    padding: 0 20px;
    text-align: right;
  }
}

.order-details {
  .cart-table {
    margin: -30px 0 40px;
    .cart-row .cart-width-2 {
      width: 61%;
    }
  }
  .payment {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e7e7e7;
    .text {
      p {
        margin: 0;
        color: #222;
        font-size: 17px;
        line-height: 19px;
        font-weight: 300;
        span {
          color: #5cb85c;
          &.not-paid {
            color: #e0211b;
          }
        }
        a.pay {
          position: relative;
          margin-left: 10px;
          color: $color-links;
          cursor: pointer;
          @include transition(all 0.3s ease);
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            display: block;
            width: 100%;
            height: 1px;
            background: $color-links;
            @include transition(all 0.3s ease);
          }
          &:hover {
            &:after {
              opacity: 0;
            }
          }
        }
      }
    }
    .price {
      width: 140px;
      p {
        margin: 0;
        color: #000;
        font-size: 17px;
        line-height: 19px;
        font-weight: 500;
        text-align: right;
      }
    }
  }
  .whole-value {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
    p {
      margin: 0;
      &.text {
        color: #000;
        font-size: 15px;
        line-height: 19px;
        font-weight: 500;
        .grey {
          color: #c4c4c4;
          font-size: 10px;
          font-weight: 600;
        }
      }
      &.number {
        margin-left: 130px;
        color: #000;
        font-size: 19px;
        line-height: 21px;
        font-weight: 600;
      }
    }
  }
  .address {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 49%;
      margin-bottom: 30px;
      &.full {
        width: 100%;
      }
      h5 {
        margin-bottom: 15px;
        color: #999;
        font-size: 15px;
        line-height: 17px;
        font-weight: 500;
        text-transform: uppercase;
      }
      p {
        margin-bottom: 10px;
        color: #222;
        font-size: 17px;
        line-height: 30px;
        font-weight: 300;
      }
    }
  }
}

.changed-password {
  width: 434px;
  margin-top: 40px;
  .buttons {
    display: flex;
    justify-content: flex-end;
    .btn {
      min-width: 163px;
      &.btn-turquoise {
        margin-left: 9px;
      }
    }
  }
}

.changed-data {
  h5 {
    margin-bottom: 30px;
    color: #666;
    font-size: 15px;
    line-height: 17px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .item {
    display: flex;
    justify-content: space-between;
    .item-half {
      width: 433px;
    }
  }
  .client_company {
    display: none;
    &.active {
      display: block;
    }
  }
  .link-other-address {
    display: flex;
    justify-content: flex-end;
    p {
      position: relative;
      padding-left: 30px;
      width: 433px;
      color: #222;
      font-size: 15px;
      font-weight: 300;
      line-height: 20px;
      cursor: pointer;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #aaa;
        background-color: #fff;
        border-radius: 2px;
      }
      &.active {
        &:before {
          border: 2px solid #07aba3;
          background: #fff url(../img/icons/selected-checkbox.png) no-repeat center center;
        }
      }
    }
  }
  .other-address {
    display: none;
    margin-top: 40px;
    &.active {
      display: block;
    }
  }
  .additional-info {
    margin-top: 30px;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    .btn {
      min-width: 163px;
      &.btn-turquoise {
        margin-left: 9px;
      }
    }
  }
}
.client-type {
  display: flex;
  margin-left: 20px;
  & > div {
    margin-left: 20px;
  }
}
.vat-eu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 49%;
  margin-bottom: 25px;
  .best-checkbox {
    width: 100px;
  }
  .nip-kod {
    display: none;
    .form-group {
      margin: 0 0 0 15px;
      width: 70px;
    }
  }
  &.active {
    .nip-kod {
      display: block;
    }
  }
}

.payment-delivery {
  margin: 16px 0 50px;
  .option {
    margin-bottom: 7px;
    .payment-details {
      display: none;
      margin: 20px 0 23px 54px;
      p.details {
        margin: 0;
        color: #222;
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
        strong {
          color: #b1b1b2;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }
    &.active .payment-details {
      display: block;
    }
    &.option-hidden {
      display: none;
      &.see {
        display: block;
      }
    }
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 25px 14px 0;
    border: 1px solid #ccc;
    @include border-radius(4px);
    &.selected {
      border: 2px solid $color-turquoise;
      input[type="radio"] + label:before {
        border-color: #5cb85c;
      }
      .radio-success input[type="radio"] + label:after {
        background-color: #5cb85c;
        -ms-transform: scale(1, 1);
        transform: scale(1, 1);
      }
    }
    .radio {
      width: 500px;
      label {
        color: #222;
        font-size: 17px;
        line-height: 17px;
        font-weight: 300;
      }
    }
    p.price {
      margin: 0;
      color: #222;
      font-size: 17px;
      line-height: 19px;
      font-weight: 500;
    }
    .turquoise {
      cursor: pointer;
      color: $color-turquoise;
      font-size: 15px;
      font-weight: 400;
      &:after {
        content: '';
        display: block;
        margin-top: -2px;
        width: 100%;
        height: 1px;
        background: $color-turquoise;
      }
    }
  }
  .see-all {
    display: inline-block;
    margin-top: 15px;
    padding: 5px 20px;
    color: #a8aeae;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    &:after {
      content: '';
      display: block;
      margin-top: -2px;
      width: 100%;
      height: 1px;
      background: #a8aeae;
    }
    &.hide {
      display: none;
    }
  }
  .delivery-country {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px 15px 39px;
    border: 1px solid #ccc;
    @include border-radius(4px);
    .text {
      display: flex;
      color: #222;
      font-size: 17px;
      line-height: 18px;
      font-weight: 300;
      span {
        margin-left: 10px;
      }
      .flag {
        margin-left: 20px;
        padding-left: 36px;
        &.pl {
          background: url(../img/icons/pl.png) no-repeat 0 0;
        }
        &.en {
          background: url(../img/icons/en.png) no-repeat 0 0;
        }
        &.de {
          background: url(../img/icons/de.png) no-repeat 0 0;
        }
        &.cz {
          background: url(../img/icons/cz.png) no-repeat 0 0;
        }
        &.sk {
          background: url(../img/icons/sk.png) no-repeat 0 0;
        }
        &.ua {
          background: url(../img/icons/ua.png) no-repeat 0 0;
        }
        &.fr {
          background: url(../img/icons/fr.png) no-repeat 0 0;
        }
        &.hu {
          background: url(../img/icons/hu.png) no-repeat 0 0;
        }
        &.it {
          background: url(../img/icons/it.png) no-repeat 0 0;
        }
        &.ro {
          background: url(../img/icons/ro.png) no-repeat 0 0;
        }
        &.se {
          background: url(../img/icons/se.png) no-repeat 0 0;
        }
        &.no {
          background: url(../img/icons/no.png) no-repeat 0 0;
        }
      }
    }
    .change {
      cursor: pointer;
      color: $color-turquoise;
      font-size: 15px;
      font-weight: 400;
      &:after {
        content: '';
        display: block;
        margin-top: -2px;
        width: 100%;
        height: 1px;
        background: $color-turquoise;
      }
    }
  }
}

.discount-code {
  display: flex;
  align-items: center;
  p {
    margin: 0;
    color: #222;
    font-size: 14px;
    line-height: 14px;
    font-weight: 300;
  }
  input.form-control {
    width: 155px;
    margin: 0 10px 0 20px;
  }
}

#contact-page-map {
  display: flex;
  justify-content: center;
  .city {
    width: 737px;
    height: 566px;
    background: url(../img/city.jpg) no-repeat;
  }
  #map {
    margin-left: 28px;
    flex: 1;
    height: 566px;
  }
}

.subpage-contact {
  margin: 60px 0;
  .inside {
    display: flex;
    justify-content: space-between;
    .left-col {
      flex: 1;
      h3 {
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e7e7e7;
        color: #222;
        font-size: 24px;
        font-weight: 500;
      }
      p {
        margin-bottom: 30px;
        color: #ababab;
        font-size: 15px;
        font-weight: 300;
      }
      textarea {
        height: 200px;
      }
      .form-simple .form-check-input {
        margin-top: 5px;
      }
      .form-simple .invalid-feedback {
        left: 6px;
      }
      .bottom-form {
        margin-top: 30px;
        .btn-black {
          width: 100%;
        }
      }
    }
    .right-col {
      margin-left: 110px;
      width: 550px;
      padding: 60px 66px;
      background-color: #f5f5f5;
      h4 {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 2px solid $color-links;
        color: #222;
        font-size: 19px;
        font-weight: 500;
      }
      p {
        margin-bottom: 40px;
        color: #222;
        font-size: 17px;
        font-weight: 300;
      }
      h5 {
        color: #222;
        font-size: 19px;
        line-height: 30px;
        font-weight: 300;
        a {
          display: inline-block;
          color: $color-links;
          font-weight: 400;
          @include transition(all 0.3s ease);
          &:after {
            content: '';
            display: block;
            margin-top: -2px;
            width: 100%;
            height: 1px;
            background: $color-links;
            @include transition(all 0.3s ease);
          }
          &:hover {
            &:after {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

.subpage-blog-details {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  .blog-details {
    width: 974px;
    .blog-details__header {
      margin-bottom: 50px;
      h3 {
        position: relative;
        margin-bottom: 5px;
        padding-bottom: 24px;
        border-bottom: 1px solid #c9cacf;
        color: #333;
        font-size: 36px;
        line-height: 36px;
        font-weight: 500;
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 189px;
          height: 3px;
          background-color: $color-links;
        }
      }
      .date {
        color: #969ca1;
        font-size: 14px;
        font-weight: 300;
      }
    }
    img {
      display: block;
      max-width: 100%;
      margin: 40px 0;
    }
  }
  .blog-sidebar {
    width: 374px;
    margin-top: 15px;
    aside {
      margin-bottom: 30px;
      h3 {
        margin-bottom: 43px;
        padding-bottom: 24px;
        border-bottom: 1px solid #c9cacf;
        color: #222;
        font-size: 17px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
    .widget-latest-news {
      .items {
        .item a {
          display: flex;
          justify-content: space-between;
          margin-bottom: 19px;
          padding-bottom: 16px;
          border-bottom: 1px solid #f3f3f3;
          .photo {
            width: 85px;
            img {
              display: block;
              max-width: 100%;
              @include transition(all 0.3s ease);
            }
          }
          .desc {
            width: 272px;
            p {
              &.date {
                margin-bottom: 6px;
                color: #ababad;
                font-size: 13px;
                font-weight: 500;
                text-transform: uppercase;
              }
              &.title {
                margin: 0;
                color: #414449;
                font-size: 15px;
                font-weight: 500;
                line-height: 17px;
              }
            }
          }
          &:hover {
            .photo {
              img {
                opacity: 0.9;
              }
            }
            .desc {
              p.title {
                text-decoration: underline;
              }
            }
          }
        }
        .item:last-child a {
          border-bottom: 0;
        }
      }
    }

  }
}

.subpage-gallery {
  .inside {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    .item {
      margin: 0 20px 20px 0;
      a .photo {
        width: 311px;
        height: 311px;
        overflow: hidden;
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
        }
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

.messages {
  margin: 0 auto 22px;
  padding: 20px;
  width: 76%;
  border: 2px solid transparent;
  @include border-radius(4px);
  color: #222;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  &.messages-red {
    background-color: #feeded;
    border-color: #f34040;
  }
  &.messages-orange {
    background-color: #fff5eb;
    border-color: #fbb059;
  }
  &.messages-green {
    background-color: #e8fcf4;
    border-color: #17d384;
  }
}

.product-inside {
  margin: 45px 0 80px;
  .top {
    display: flex;
    padding-bottom: 50px;
    .left-col {
      width: 698px;
      position: sticky;
      top: 110px;
      align-self: flex-start;
    }
    .right-col {
      width: 555px;
      margin-left: 90px;
      h2.category {
        margin-bottom: 10px;
        color: #ccc;
        font-family: 'Playfair Display', serif;
        font-size: 28px;
        line-height: 30px;
        font-weight: 700;
      }
      h3.name {
        color: #222;
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
      }
      .rating {
        margin-top: 30px;
        display: flex;
        align-items: center;
        p {
          margin: 0 0 0 25px;
          color: #1a1a1a;
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
          span {
            margin-right: 10px;
            color: #999;
          }
        }
      }
      .variants-color {
        margin-top: 40px;
        p {
          margin-bottom: 10px;
          color: #1a1a1a;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
        }
        .items {
          display: flex;
          flex-wrap: wrap;
          .item {
            margin: 0 16px 10px 0;
            &:last-child {
              margin-right: 0;
            }
            a {
              display: block;
              padding: 10px;
              border: 2px solid #f6f6f6;
              background-color: #f6f6f6;
              .photo {
                width: 44px;
                height: 62px;
                overflow: hidden;
                img {
                  display: block;
                  margin: 0 auto;
                  max-width: 100%;
                }
              }
              &.active {
                border-color: $color-links;
              }
            }
          }
        }
      }
      .to-cart {
        margin-top: 13px;
        display: flex;
        justify-content: space-between;
        .quantity {
          display: flex;
          button {
            display: block;
            width: 43px;
            padding: 0;
            background-color: #fff;
            border-style: solid;
            border-color: #aaa;
            span {
              display: block;
              text-indent: -9999px;
            }
            &.inactive span {
              opacity: 0.5;
            }
            &.minus {
              border-width: 2px 0 2px 2px;
              -webkit-border-top-left-radius: 2px;
              -webkit-border-bottom-left-radius: 2px;
              -moz-border-radius-topleft: 2px;
              -moz-border-radius-bottomleft: 2px;
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
              span {
                background: url(../img/icons/minus.png) no-repeat center center;
              }
            }
            &.plus {
              border-width: 2px 2px 2px 0;
              -webkit-border-top-right-radius: 2px;
              -webkit-border-bottom-right-radius: 2px;
              -moz-border-radius-topright: 2px;
              -moz-border-radius-bottomright: 2px;
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
              span {
                background: url(../img/icons/plus.png) no-repeat center center;
              }
            }
          }
          input.text {
            width: 63px;
            padding: 8px 0;
            background-color: #fff;
            border-width: 2px 1px;
            border-style: solid;
            border-color: #aaa;
            text-align: center;
            font-size: 17px;
            font-weight: 300;
            color: #3f474b;
            &::-webkit-input-placeholder { color: #3f474b !important; opacity: 1; }
            &:-moz-placeholder { color: #3f474b !important; opacity: 1; }
            &::-moz-placeholder { color: #3f474b !important; opacity: 1; }
            &:-ms-input-placeholder { color: #3f474b !important; opacity: 1; }
          }
        }
        .btn-turquoise {
          min-width: 335px;
        }
        .favorite {
          display: block;
          width: 44px;
          height: 44px;
          background: url(../img/icons/wishlist2.png) no-repeat center center;
          border: 2px solid #b2b2b2;
          cursor: pointer;
          @include transition(all 0.3s ease);
          &:hover {
            border-color: $color-links;
            background: $color-links url(../img/icons/wishlist2-white.png) no-repeat center center;
          }
        }
      }
      .info-box {
        margin-top: 54px;
        border: 1px solid #d0d1d3;
        .item {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 20px 24px 20px 62px;
          border-bottom: 1px solid #d0d1d3;
          &.package {
            background: url(../img/icons/package.png) no-repeat 23px center;
          }
          &.car {
            background: url(../img/icons/car.png) no-repeat 23px center;
          }
          p {
            margin: 0;
            color: #222;
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
          }
          &:last-child {
            border-bottom: 0;
          }
        }
      }
      .detailed {
        margin-top: 25px;
        padding: 0 0 30px 38px;
        border-bottom: 1px solid #d0d1d3;
        h5 {
          margin-bottom: 20px;
          color: #222;
          font-size: 17px;
          line-height: 24px;
          font-weight: 500;
        }
        .ytlink {

        }
        p {
          margin-bottom: 0;
          color: #222;
          font-size: 14px;
          line-height: 24px;
          font-weight: 300;
          strong {
            font-weight: 600;
          }
          a {
            color: $color-links;
            cursor: pointer;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .describe {
    h4 {
      margin-bottom: 20px;
      padding-bottom: 12px;
      border-bottom: 2px solid #e7e7e7;
      color: #222;
      font-size: 19px;
      line-height: 23px;
      font-weight: 500;
    }
    p {
      color: #222;
      font-size: 17px;
      line-height: 36px;
    }
  }
  .product-reviews {
    margin-top: 100px;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 48px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e7e7e7;
      h4 {
        color: #222;
        font-size: 19px;
        line-height: 23px;
        font-weight: 500;
      }
      p.grades {
        margin: 0;
        color: #222;
        font-size: 17px;
        font-weight: 500;
        line-height: 23px;
        .grey {
          color: #999;
        }
      }
    }
    .inside {
      display: flex;
      justify-content: space-between;
      .left-col {
        flex: 1;
        .item {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 34px;
          padding-bottom: 25px;
          border-bottom: 1px solid #d0d1d3;
          &:last-child {
            margin-bottom: 0;
          }
          .who {
            width: 249px;
            margin-bottom: 10px;
            padding: 0 0 0 79px;
            min-height: 59px;
            background: url(../img/icons/who.png) no-repeat 0 0;
            h5 {
              margin: 8px 0 4px;
              color: #000;
              font-size: 15px;
              font-weight: 500;
            }
          }
          p {
            margin-bottom: 10px;
            width: 670px;
            color: #000;
            font-size: 15px;
            line-height: 30px;
            font-weight: 300;
          }
          time {
            width: 100%;
            color: #666768;
            font-size: 15px;
            font-weight: 400;
            text-align: right;
          }
        }
      }
      .right-col {
        width: 433px;
        margin-left: 47px;
        h5 {
          margin-bottom: 30px;
          color: #222;
          font-size: 17px;
          font-weight: 500;
        }
        .form-simple textarea {
          height: 110px;
        }
        .rate {
          margin-top: -10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .rating {
            display: flex;
            align-items: center;
            p {
              margin: 0 10px 0 0;
              color: #767676;
              font-size: 13px;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}

.choose-size {
  margin-top: 55px;
  .form-control {
    width: inherit;
    flex: 1;
    height: 48px;
    @include border-radius(0);
  }
  .btn-light {
    padding: 10px 30px;
    background: #fff;
    border: 2px solid #b2b2b2;
    @include border-radius(0);
    color: #222;
    font-size: 15px;
    font-weight: 300;
  }
  .dropdown-item.active, .dropdown-item:active {
    background-color: #f6f6f6;
    color: #222;
  }
  .dropdown-menu {
    @include border-radius(0);
    li {
      margin: 0 10px;
      border-bottom: 1px solid #f3f3f6;
      &:last-child {
        border-bottom: 0;
      }
      a {
        padding: 10px 20px;
        span.text {
          display: block;
        }
      }
      &.disabled {
        a span.text {
          .carpet_size {
            color: #b3b2b1;
          }
          .carpet_price {
            color: #b3b2b1;
          }
          .carpet_availability {
            color: #b3b2b1;
          }
        }
      }
    }
  }
  .carpet_size {
    display: inline-block;
    width: 194px;
    color: #222;
    font-size: 13px;
    font-weight: 300;
  }
  .carpet_price {
    display: inline-block;
    color: #222;
    font-size: 13px;
    font-weight: 500;
    &.discount {
      color: #db0404;
      s {
        color: #949494;
      }
    }
  }
  .carpet_availability {
    display: inline-block;
    float: right;
    color: #148d47;
    font-size: 12px;
    font-weight: 500;
  }

  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
    background-color: #fff;
  }

  .dropdown-toggle:after {
    position: absolute;
    right: 12px;
    top: 17px;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 11px;
    background: url(../img/icons/arrow-bottom.png) no-repeat center center;
    border: 0;
  }

}

.chemex-product-photos {
  .app-figure {
    display: flex;
    a.MagicZoom {
      order: 2;
    }
    .selectors {
      order: 1;
      margin-right: 20px;
      width: 119px;
      .mz-thumb {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
        width: 119px;
        height: 119px;
        border: 2px solid #d0d1d3;
        overflow: hidden;
        &.mz-thumb-selected {
          border-color: $color-links;
        }
        img {
          display: block;
          max-width: 100%;
          padding: 0;
          border: 0;
        }
      }
      .slick-arrow {
        position: absolute;
        top: 525px;
        display: block;
        width: 34px;
        height: 23px;
        border: 0;
        text-indent: -9999px;
        outline: none;
        &.slick-prev {
          right: 20px;
          background: url(../img/icons/arrow-top-slider.png) no-repeat center center;
          @include transition(all 0.3s ease);
          &:hover {
            background: url(../img/icons/arrow-top-slider-hover.png) no-repeat center center;
          }
        }
        &.slick-next {
          left: 20px;
          background: url(../img/icons/arrow-bottom-slider.png) no-repeat center center;
          @include transition(all 0.3s ease);
          &:hover {
            background: url(../img/icons/arrow-bottom-slider-hover.png) no-repeat center center;
          }
        }
      }
    }
  }
}

.favourites_count, .comparison_count {
  min-width: 17px;
  text-align: center;
}
